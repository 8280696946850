module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Aeonik","Louize","Aeonik-Light","Aeonik-Medium","Aeonik-Bold"],"urls":["fonts.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-analytics/gatsby-browser.js'),
      options: {"plugins":[],"writeKey":"d3HYIFuB2XALxTtcm5wXIIhLus7isESv","eventName":"attache-landing"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-antd-starter","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"3555bb720d4f9da5fa67c34739ff288d"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
